
import React from "react";
import LavaLamp from "../components/lavalamp/LavaLamp";
import Layout from "../components/layout";
import SEO from "../components/seo";


const AboutPage = () =>(
  
  
  <><LavaLamp pos = "5"></LavaLamp>
  <Layout>
    <SEO title="Page two" />
  
    <h4>Just math 24 is a math <b>GAME</b></h4>
    </Layout>
</>)

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *     cols: 2,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];

 */

export default AboutPage
